import React, { useState, useEffect } from "react";
import './App.css';
import Amplify, {
  Auth,
  Storage,
  Hub,
} from 'aws-amplify';
import {
//  withAuthenticator,
  Button,
  Flex,
  IconCloud,
  SelectField,
  TextField,
} from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';


import '@aws-amplify/ui-react/styles.css';


awsconfig.oauth.redirectSignIn = `${window.location.origin}/`;
awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;

Amplify.configure(awsconfig);

const App: React.FC = (props: any) => {
  const [user, setUser] = useState(null);
  const [key, setKey] = useState(null);
  const [region, setRegion] = useState(null);
  const [cotraveler, setCotraveler] = useState(0);
  const level_of_protection = "protected";

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      console.debug('userEffect()->Hub.listen() event: ', event);
      console.debug('userEffect()->Hub.listen() data: ', data);
      switch (event) {
        case 'signIn':
          getUser().then(userData => setUser(userData));
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
          console.warn('Sign in failure', data);
          break;
        default:
          break;
      }
    });

    getUser().then(userData => setUser(userData));
  }, []);

  useEffect(() => {
    //const subscription = props.source.subscribe();
    return () => {
      //subscription.unsubscribe();
    };
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => console.log('Not signed in'));
  }

  const uploadFile = async (file) => {
    try {
      setKey(null);
      await Storage.put(file.name, file, {
        contentType: file.type,
        resumable: true,
        level: level_of_protection,
        completeCallback: (event) => {
          console.debug(`Storage.put()->completeCallback: `, event);
          console.log(`Successfully uploaded ${event.key}`);
          setKey(event.key);
        },
        progressCallback: (progress) => {
          console.debug(`Storage.put()->progressCallback: `, progress);
          console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        },
        errorCallback: (err) => {
          console.error('Unexpected error while uploading', err);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const priceItem = async (event) => {
    try {
      console.debug("Event: ", event);
      console.debug("Key: ", key);
      console.debug("Region: ", region);
      console.debug("Cotraveler: ", cotraveler);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {user ? (
        <div>
          <h1>Hello {user.username.replace('amazonfederate_', '')}</h1>
          <hr />

          <Flex as="form" direction="column">
            <SelectField
              label="Regions"
              placeholder="Pick a region..."
              variation="default"
              descriptiveText="Select a region"
              errorMessage="Must select a region"
              labelHidden={false}
              isDisabled={false}
              isRequired={true}
              value={region}
              onChange={(e) => setRegion(e.target.value)}
              options={['us-east-1','us-east-2','us-west-1','us-west-2']}
            />
            <TextField
              autoComplete="off"
              descriptiveText="Co Traveler is simply a dollar amount"
              direction="column"
              hasError={false}
              inputMode="text"
              isDisabled={false}
              isMultiline={false}
              isReadOnly={false}
              isRequired={true}
              label="Cotraveler"
              labelHidden={false}
              name="cotraveler"
              placeholder="Enter a cotraveler..."
              type="text"
              value={cotraveler}
              wrap="nowrap"
              onChange={(e) => setCotraveler(e.target.value)}
            />
          {region ? (
            <Flex direction="row">
          {key ? (
              <Button
                onClick={(e) => { priceItem(e); }}
                variation="primary">Price&nbsp;<IconCloud />{key}
              </Button>
          ) : (
              <input
                type="file"
                accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={(e) => { uploadFile(e.target.files[0]); }}
              />
          )}
            </Flex>
          ) : (<p>Please pick a region...</p>)}
          </Flex>
          <hr />

          <Button onClick={() => Auth.signOut()}>Sign Out</Button>
        </div>
      ) : (
        <div>
          <Button onClick={() => Auth.federatedSignIn({customProvider: "AmazonFederate"})}>Signin With Federate</Button>
        </div>
      )}
    </div>
  );
}

export default App;

