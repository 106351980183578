/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://yxtgseby35g2fh6ulfl33rsxi4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-fpuupk2zj5bltievzjeueayrsm",
    "aws_cognito_identity_pool_id": "us-east-1:f7d8f015-b396-45de-9945-5fb9fd01cf1c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_marICJCzc",
    "aws_user_pools_web_client_id": "56rq224r267h6lt80va26ra26d",
    "oauth": {
        "domain": "epicpricingestimates703245ca-703245ca-main.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://epic-estimates.sa.aws.dev/",
        "redirectSignOut": "http://localhost:3000/,https://epic-estimates.sa.aws.dev/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "epicpricingestimates-storage-c5dedc93234912-main",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
